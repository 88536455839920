<template>
  <div class="compartilhe-container">
    
    <!-- -->
      <section class="container-fluid" style="background:#F9F7EA;">

        <div class="row">
            
            <div class="col-sm-12 px-lg-5">
            <br>
                      <h3 class="txt-titulo-roxo bold" >Compartilhe sua receita</h3>
                      <p class="txt-paragrafo-roxo">
Tarimba na Cozinha é também um espaço para criar e interagir. Faça nossa rotina diária ficar mais divertida e gostosa compartilhando suas receitas com todos os nossos amigos tarimbados.
Clique aqui e veja como você pode fazer.</p>

<button @click="criarReceita" class="btn btn-light btn-block btn-criar-receita bold" tabindex="0">Crie uma nova receita</button>

<br>
<p class="txt-paragrafo-roxo">As receitas serão analisadas pela equipe de curadoria do Chef Marcelo, para aprovação e possível publicação.
<br><br>
Você poderá enviar quantas receitas quiser. Depois, acompanhe seu status de análise que será publicado nesta página. Participe e compartilhe suas experiências!</p>
<br>
<Anuncio :id="4" padrao="/img/banner-propaganda5.png" class="d-none d-sm-block" :mobile="false" />
<Anuncio :id="4" padrao="/img/banner-propaganda5.png" class="d-sm-none" :mobile="true" />
<br>

<Busca :filtros.sync="filtrosBusca" @buscar="buscarResultados" :showIngredientes="false" :showFiltroStatus="true" urlNome="autocomplete/receitasCriadas/"></Busca>

<!-- table -->
<div class="table-responsive" id="receitasCriadas">
<table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col">Nome da receita</th>
      <th scope="col">Dieta</th>
      <th scope="col">Dificuldade</th>
      <th scope="col">Custo aproximado</th>
      <th scope="col">Quant. Pessoas</th>
      <th scope="col">Tempo de preparo</th>
       <th scope="col">Status</th>
       <th scope="col">Ações</th>
       
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in receitas" :key="'receita'+index">
      
      <td>{{item.nome}}</td>
      <td>{{item.dieta}}</td>
      <td>{{item.dificuldade}}</td>
      <td>{{item.custo}}</td>
      <td>{{item.rendimento}} Pessoa(s)</td>
      <td>{{item.tempo}} Min</td>
      <td>{{item.status}}</td>
      <td>

       <span @click="editar(item.id)" title="Editar" style="cursor:pointer;margin:0 10px;"> <svg  width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg></span>

        <span v-if="item.status == 'Rascunho' || item.status == 'Negado'" @click="excluir(item.id)" title="Excluir" style="cursor:pointer;margin:0 10px;">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>
        </span>
      </td>
     
    </tr> 
   
  </tbody>
</table>
</div>
<!-- -->

<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Sem Resultados...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS RECEITAS</button>
         </div>
        
        <br><br>


          </div>
                  
       </div>
                  
      </section>
<!-- -->


 <div class="modal" id="modal-compartilhe" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>






  </div>
</template>

<style>
.txt-titulo-roxo{color:#440D45;font-weight:bold;}

.btn-criar-receita {
    margin-top: 2%;
   
    background-color: #400041;
    border: none;
    color: #fff;
    max-width:300px !important;
}
</style>

<script>
import servidor from '@/servidor'
import Busca from '@/components/Busca'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'Compartilhe',
  metaInfo: {
    title: 'Compartilhe sua receita - Tarimba na cozinha'
  },
  components: {Busca,Anuncio},
  data(){
    return {
      semResultados:false,
      showLoading:false,
      receitas:[],
      filtrosBusca: new FormData(),
      texto:''
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }
  },
  methods:{
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-compartilhe').modal('show');
   },
    excluir(id){
      if(confirm('Deseja deletar esse item?')){
        fetch(servidor+'buscaTarimba/deletarReceita/'+ id)
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
            let filtro = new FormData();
            filtro.append('paginacao',0);
            this.buscarResultados(filtro);
           
          });
      }
    },
     editar(id){
      this.$router.push('/editarReceita/'+id);
    },
    criarReceita(){
      this.$router.push('/criarReceita');
    },
    buscarResultados(filtros){
       this.semResultados = false;
       this.receitas = [];
       this.showLoading = true;
       filtros.append('paginacao',0);
       fetch(servidor+'buscaTarimba/receitasCriadas/'+ this.getUsuario,{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            this.receitas = data;
            this.showLoading = false;
            console.log(data)
          });
    },
    paginar(){
       this.semResultados = false;
       this.showLoading = true;
       this.filtrosBusca.append('paginacao',this.receitas.length);
       fetch(servidor+'buscaTarimba/receitasCriadas/'+ this.getUsuario,{method:"POST", body: this.filtrosBusca})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            data.forEach(element => {
                this.receitas.push(element);
            });
            this.showLoading = false;
           
          });

    }
  },
  mounted(){
    let filtro = new FormData();
    filtro.append('paginacao',0);
    this.buscarResultados(filtro);

    if(this.$store.state.msg != null && this.$store.state.msg != ''){
      this.abrirModal(this.$store.state.msg);
      this.$store.commit('alterarMensagem','');
    }
  }
}
</script>
